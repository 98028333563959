<template>
  <section id="documentation">
    <header></header>
    <article>
      <ul v-if="appData.docs && appData.seasons">
        <li>
          <strong>Jäckli Geologie /<br />Swiss Alertis Einsatzzentrale</strong>
          <ul>
            <li>
              Notfallnummer:
              <a class="emergency" href="tel:+41443444111">+41 44 3 444 111</a>
            </li>
          </ul>
        </li>
        <li>
          <strong>Aktuelle Pikett-Periode</strong>
          <ul>
            <li>
              {{ currentPeriod.period.title }} (KW
              {{ currentPeriod.period.kw }})
            </li>
            <li>
              <a class="emergency" :href="'tel:' + currentPeriod.p.mobile"
                >{{ currentPeriod.p.firstname }}
                {{ currentPeriod.p.familyname }}</a
              >
            </li>
            <li>
              <a class="emergency" :href="'tel:' + currentPeriod.s.mobile"
                >{{ currentPeriod.s.firstname }}
                {{ currentPeriod.s.familyname }}</a
              >
              (Stv.)
            </li>
          </ul>
        </li>
        <li v-for="(folder, i) in appData.docs.folders" :key="i">
          <strong>{{ folder.title }}</strong>
          <ul>
            <li v-for="(file, j) in folder.files" :key="j">
              <a :href="file.url" target="_blank">{{ file.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <header>
        <label class="input">
          <input
            type="text"
            placeholder="Dokumente filtern"
            v-model="keywords"
          />
        </label>
      </header>
      <table v-if="appData.docs">
        <thead>
          <tr>
            <th>Nat.-Nr.</th>
            <th>Kanton</th>
            <th>Ort</th>
            <th>Strecke</th>
            <th>Markierung</th>
            <th>Dokumente</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(document, i) in documents" :key="i">
            <td>{{ document.natNr }}</td>
            <td>{{ document.canton }}</td>
            <td>
              <span v-for="(city, i) in document.cities" :key="i">{{
                city
              }}</span>
            </td>
            <td>{{ document.route }}</td>
            <td>{{ document.marks }}</td>
            <td>
              <ul>
                <li v-for="(file, k) in document.files" :key="k">
                  <a :href="file.url" target="_blank">{{
                    getFileTitle(file)
                  }}</a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </section>
</template>

<script>
export default {
  name: "Planner",
  props: {
    appData: Object,
  },
  data() {
    return {
      keywords: ``,
    };
  },
  computed: {
    documents() {
      let documents = [];
      let keywords = this.keywords.toLowerCase().trim();
      this.appData.docs.documentation.folders.forEach((folder) => {
        folder.filePackages.forEach((filePackage) => {
          if (
            !keywords.length ||
            folder.title.toLowerCase().includes(keywords) ||
            filePackage.title.toLowerCase().includes(keywords)
          ) {
            let loc = filePackage.locality;
            let canton = loc.split(` `).pop();
            let cities = loc.substring(0, loc.length - 3).split(`, `);
            let route = filePackage.title.split(`;`)[0];
            let marks = filePackage.title.split(`;`)[1];
            documents.push({
              natNr: filePackage.natNr,
              canton: canton,
              cities: cities,
              route: route,
              marks: marks.split(`-`).join(` – `),
              files: filePackage.files,
            });
          }
        });
      });
      return documents;
    },
    currentPeriod() {
      let i,
        j,
        k,
        start,
        now = new Date(),
        today,
        day,
        end,
        season,
        period;
      for (i = this.appData.seasons.length; i--; ) {
        season = this.appData.seasons[i];
        start = new Date(season.start);
        end = new Date(season.end);
        if (start <= now && now <= end) {
          for (j = season.periods.length; j--; ) {
            period = season.periods[j];
            start = new Date(period.start);
            end = new Date(period.end);
            if (start <= now && now <= end) {
              today = now.toISOString().split("T")[0];
              for (k = period.days.length; k--; ) {
                if (period.days[k].date === today) {
                  day = period.days[k];
                  return {
                    period: period,
                    p: this.getUserById(parseInt(day.p)),
                    s: this.getUserById(parseInt(day.s)),
                  };
                }
              }
            }
          }
        }
      }
      return null;
    },
  },
  methods: {
    getFileTitle(file) {
      let name = file.name.toLowerCase();
      let descr = file.description.toLowerCase();
      if (descr.length > 0) {
        return file.description;
      } else if (name.includes(`b1_`)) {
        return `Beilage 1`;
      } else if (name.includes(`b2_`)) {
        return `Beilage 2`;
      } else if (descr === `kg` || name.includes(`kg_`)) {
        return `Kurzgutachten`;
      } else if (descr === `ob` || name.includes(`ob`)) {
        return `Objektblatt`;
      } else {
        return `Objektblatt`;
      }
    },
    getUserById(id) {
      return this.appData.users.find((user) => user.id === id);
    },
  },
};
</script>

<style scoped>
#documentation {
  padding: 1em 0.5em;
}
#documentation > article {
  margin-bottom: 1em;
}
#documentation > article > header {
  display: flex;
  margin-bottom: 1em;
}
article > ul {
  display: flex;
  flex-wrap: wrap;
}
article > ul > li {
  padding: 0 0 1em;
  margin-right: 2em;
}
article > ul > li li {
  padding: 0.1em 0;
}
a.emergency {
  font-weight: bold;
  color: #b00;
}
td ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
td li {
  display: inline-block;
  margin-right: 0.5em;
  padding: 0;
}
td span {
  display: block;
}
</style>