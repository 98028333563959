<template>
  <div id="app">
    <Login v-if="!userData || !userData.id" @submit="authenticate($event)" />
    <section v-else>
      <header>
        <nav>
          <ul>
            <li
              @click="setView('documentation')"
              class="documentation"
              :class="{ active: userData.view === 'documentation' }"
              title="Info"
            >
              <span class="icon"></span>
              <span class="caption">Info</span>
            </li>
            <li
              @click="setView('contact')"
              class="contact"
              :class="{ active: userData.view === 'contact' }"
              title="Kontakt"
            >
              <span class="icon"></span>
              <span class="caption">Kontakt</span>
            </li>
            <li
              style="opacity: 0.25; pointer-events: none"
              @click="setView('map')"
              class="map"
              :class="{ active: userData.view === 'map' }"
              title="Karte"
            >
              <span class="icon"></span>
              <span class="caption">Karte</span>
            </li>
            <li
              @click="setView('planner')"
              class="schedule"
              :class="{ active: userData.view === 'planner' }"
              title="Einsatzplan"
            >
              <span class="icon"></span>
              <span class="caption">Einsatzplan</span>
            </li>
            <li class="user">
              <span class="icon"></span>
              <span class="caption">{{ userData.code || userData.name }}</span>
              <ul>
                <li @click="logout()">Abmelden</li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
      <Documentation
        v-if="userData.view === 'documentation'"
        :appData="appData"
      />
      <Contact v-if="userData.view === 'contact'" :appData="appData" />
      <Map v-if="userData.view === 'map'" :appData="appData" />
      <Planner
        v-if="userData.view === 'planner'"
        :appData="appData"
        :userData="userData"
        @cellclicked="cellClicked($event)"
      />
    </section>
  </div>
</template>

<script>
import Login from "./components/login/Login.vue";
import Documentation from "./components/documentation/Documentation.vue";
import Contact from "./components/contact/Contact.vue";
import Map from "./components/map/Map.vue";
const Planner = () => import("./components/planner/Planner.vue");
import axios from "axios/dist/axios.min.js";

export default {
  name: "App",
  components: {
    Login,
    Documentation,
    Contact,
    Map,
    Planner,
  },
  data() {
    return {
      requestHost: "https://transitgas.jaeckli.ch/api/",
      appData: {
        seasons: null,
        users: null,
        docs: null,
      },
      userData: {
        view: "documentation",
        id: null,
        code: "",
        roles: [],
        name: "",
      },
    };
  },
  mounted() {
    this.authenticate();
  },
  methods: {
    setView(name) {
      this.userData.view = name;
    },
    authenticate(authData) {
      let postParams = this._getPostParams("authenticate");
      if (authData) {
        postParams.append("username", authData.username);
        postParams.append("password", authData.password);
      }
      axios.defaults.withCredentials = true;
      axios
        .post(this.requestHost, postParams)
        .then((result) => {
          if (result.data.id) {
            this.userData.id = result.data.id;
            this.userData.code = result.data.code;
            this.userData.name = result.data.name;
            this.userData.roles = result.data.roles;
            localStorage.setItem("trg.csrf.name", result.data.csrf_name);
            localStorage.setItem("trg.csrf.value", result.data.csrf_value);
            this.getUsers();
            this.getSeasons();
            this.getDocuments();
          } else {
            this.userData.id = null;
            this.userData.code = "";
            this.userData.name = "";
            this.userData.roles = [];
            if (authData) {
              this.notification = {
                modal: false,
                type: "bad",
                message:
                  "Benutzername oder Passwort falsch. Versuchen Sie es erneut.",
                timeout: 5000,
              };
              alert(this.notification.message);
            }
          }
        })
        .catch((error) => {
          alert("Error:", error);
          window.console.log("error:", error);
        });
    },
    logout() {
      let postParams = this._getPostParams("logout");
      axios
        .post(this.requestHost, postParams)
        .then(() => {
          localStorage.removeItem("trg.csrf.name");
          localStorage.removeItem("trg.csrf.value");
          this.userData.id = null;
          this.userData.code = "";
          this.userData.name = "";
          this.userData.roles = [];
        })
        .catch((error) => {
          alert(error);
        });
    },
    getUsers() {
      axios
        .post(this.requestHost, this._getPostParams("getUsers"))
        .then((result) => {
          if (result.data) {
            this.appData.users = result.data;
          }
        })
        .catch((error) => {
          alert("Fehler: " + error);
        });
    },
    setUser(fieldName, fieldValue) {
      let postParams = this._getPostParams("setUser");
      postParams.append("fieldName", fieldName);
      postParams.append("fieldValue", fieldValue);
      axios
        .post(this.requestHost, postParams)
        .then((result) => {
          if (result.data.success) {
            this.getSeasons();
          } else {
            alert("Fehler: " + result.data.error);
          }
        })
        .catch((error) => {
          alert("Fehler: " + error);
        });
    },
    getSeasons() {
      axios
        .post(this.requestHost, this._getPostParams("getSeasons"))
        .then((result) => {
          if (result.data) {
            this.appData.seasons = result.data;
          }
        })
        .catch((error) => {
          alert("Fehler: " + error);
        });
    },
    cellClicked(eventObject) {
      this.setUser(eventObject.fieldName, eventObject.userId);
    },
    getDocuments() {
      axios
        .post(this.requestHost, this._getPostParams("getDocuments"))
        .then((result) => {
          if (result.data) {
            this.appData.docs = result.data;
          }
        })
        .catch((error) => {
          alert("Fehler: " + error);
        });
    },
    _getPostParams(command) {
      let postParams = new URLSearchParams();
      postParams.append("command", command);
      postParams.append(
        localStorage.getItem("trg.csrf.name"),
        localStorage.getItem("trg.csrf.value")
      );
      return postParams;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #fff;
}
#app {
  font-family: "Myriad Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #0061c8;
}

/* head buttons */
header label {
  flex-grow: 1;
  position: relative;
  margin: 0 0.25em 0 0;
  text-align: left;
  padding: 0.25em 0.5em;
  min-width: 1.5em;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(#0061c8, #005198);
  border-radius: 2px;
  cursor: pointer;
}
header label:hover {
  background: linear-gradient(#0071d8, #0061b8);
}
header label.input {
  padding: 0.25em;
}
header label.input input {
  margin: 0;
  border: 0;
  width: calc(100% - 1em);
  height: calc(100% - 1em);
}
header label.button {
  flex-grow: 0;
  text-align: center;
}
header label:last-child {
  margin: 0;
}
header label .label {
  display: block;
  font-size: 75%;
  font-weight: normal;
}
header label .selection {
  display: block;
  font-size: 100%;
}

/* common html elements */
button {
  margin: 1em 0.5em;
  padding: 0.5em 1em;
  min-width: 1.5em;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(#0061c8, #005198);
  border: none;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
}
button:hover {
  background: linear-gradient(#0071d8, #0061b8);
}
input {
  display: block;
  width: 12em;
  margin: 0.25em;
  padding: 0.5em;
  outline: none;
  border: 1px solid rgba(24, 32, 40, 0.1);
  color: #000;
  background: #fff;
}
table {
  width: 100%;
  border-collapse: collapse;
  word-break: break-word;
}
table.desktop {
  display: none;
}
table.mobile {
  display: table;
}
thead {
  color: #fff;
  background: #222;
}
tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
td,
th {
  text-align: left;
  padding: 0.25em;
}
thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
tbody th,
tbody td {
  font-weight: normal;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 59em) {
  table.desktop {
    display: table;
  }
  table.mobile {
    display: none;
  }
}
</style>

<style scoped>
header {
  position: relative;
  z-index: 1;
}
nav {
  margin: 0;
}
nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 0.33em solid #999;
  background-color: #e5e5e5;
}
nav li {
  display: inline-block;
  position: relative;
  margin: -0.33em 0 0;
  padding: 1.75em;
  height: 1em;
  border-top: 0.33em solid #999;
  cursor: pointer;
}
nav li span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}
nav li span.icon {
  z-index: 2;
  position: absolute;
  top: 0;
  background-position: center 0.25em;
  background-repeat: no-repeat;
  background-size: 3em;
}
nav li span.caption {
  z-index: 3;
  bottom: 0;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

nav li.documentation .icon {
  background-image: url(./assets/documentation.svg);
}
nav li.contact .icon {
  background-image: url(./assets/contact.svg);
}
nav li.map .icon {
  background-image: url(./assets/map.svg);
}
nav li.schedule .icon {
  background-image: url(./assets/schedule.svg);
}
nav li.user .icon {
  background-image: url(./assets/user.svg);
}
nav li:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
nav ul li.active {
  background-color: #fff;
  border-top-color: #0061c8;
}
nav ul li.active span {
  opacity: 1;
}
nav ul li.user {
  margin-left: auto;
}
nav ul li.user span {
  opacity: 1;
}
nav ul li.user ul {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  border: 0;
  background: #222;
  color: #fff;
}
nav ul li.user:hover ul {
  display: block;
}
nav ul li.user ul li {
  margin: 0;
  padding: 1em;
  border: 0;
}
nav ul li.user ul li:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

@media (min-width: 46em) {
  nav li {
    min-width: 2.5em;
  }
}
</style>