<template>
  <section id="contact">
    <header></header>
    <article>
      <header>
        <label class="input">
          <input
            type="text"
            placeholder="Personen filtern"
            v-model="keywords"
          />
        </label>
        <label class="button" @click="exporting = true">
          <span class="label">pdf</span>
          <span class="selection">⬇</span>
        </label>
      </header>
      <table v-if="users" class="desktop">
        <thead>
          <tr>
            <th>Rolle</th>
            <th>ID</th>
            <th>Name</th>
            <th>Vorname</th>
            <th>Ausbildung</th>
            <!--th>Telefon Büro</th-->
            <th>Mobile</th>
            <th>Basis-Schulung</th>
            <th>Letztes Update</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i">
            <td>{{ user.mainRole.label }}</td>
            <td :title="user.id">{{ user.code }}</td>
            <td>{{ user.familyname }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.education }}</td>
            <!--td>
              <a :href="'tel:' + user.phone">{{ user.phone }}</a>
            </td-->
            <td>
              <a :href="'tel:' + user.mobile">{{ user.mobile }}</a>
            </td>
            <td>{{ user.trgBaseEducation || "&mdash;" }}</td>
            <td>{{ user.trgLastEducation || "&mdash;" }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="users" class="mobile">
        <thead>
          <tr>
            <th>Name /<br />Vorname</th>
            <th>Mobile /<br />Telefon Büro</th>
            <th>Basis- /<br />Letzte<br />Schulung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i">
            <td>
              {{ user.mainRole.label }}<br />
              <strong
                >{{ user.firstname }}<br />{{ user.familyname }} ({{
                  user.code
                }})</strong
              ><br /><span>{{ user.education }}</span>
            </td>
            <td>
              <a :href="'tel:' + user.mobile">{{ user.mobile }}</a
              ><br /><a :href="'tel:' + user.phone">{{ user.phone }}</a>
            </td>
            <td>
              {{ user.trgBaseEducation || "&mdash;" }}<br />{{
                user.trgLastEducation || "&mdash;"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </article>
    <Export v-if="exporting" :appData="appData" @finished="exporting = false" />
  </section>
</template>

<script>
const Export = () => import("./Export.vue");
export default {
  name: "Contact",
  components: {
    Export,
  },
  props: {
    appData: Object,
  },
  data() {
    return {
      keywords: "",
      exporting: false,
    };
  },
  computed: {
    users() {
      let users = [];
      let keywords = this.keywords.toLowerCase().trim();
      this.appData.users.forEach((user) => {
        if (
          !keywords.length ||
          user.code.toLowerCase().includes(keywords) ||
          user.familyname.toLowerCase().includes(keywords) ||
          user.firstname.toLowerCase().includes(keywords)
        ) {
          users.push(user);
        }
      });
      return users;
    },
  },
  methods: {},
};
</script>

<style scoped>
#contact {
  padding: 1em 0.5em;
}
#contact > article {
  margin-bottom: 1em;
}
#contact > article > header {
  display: flex;
  margin-bottom: 1em;
}
td span {
  font-size: 80%;
}
td:not(:first-child) {
  white-space: nowrap;
}
</style>