<template>
  <div id="Login">
    <section>
      <form>
        <fieldset>
          <label
            ><span>Benutzername</span
            ><input type="text" v-model="authData.username"
          /></label>
          <label
            ><span>Passwort</span
            ><input type="password" v-model="authData.password"
          /></label>
        </fieldset>
        <button @click.prevent="loginClicked()">Login</button>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      authData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    loginClicked() {
      this.$emit("submit", this.authData);
    },
  },
};
</script>

<style scoped>
#Login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #444;
  background-image: url(./../../assets/login-picture.jpg);
  background-size: cover;
  background-position: center bottom;
}
#Login > section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 3em);
}
form {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  padding: 1em;
  border-radius: 0.2em;
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.5);
}
h1 {
  font-size: 125%;
  margin: 0 0 0.5em 0.8em;
  padding: 0;
}
h1 span {
  font-weight: normal;
  opacity: 0.67;
}
h2 {
  font-size: 112.5%;
  margin: 0.75em 0 0.25em;
  padding: 0;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
label,
span {
  display: block;
  padding: 0.25em;
}
span {
  padding-bottom: 0;
}
</style>