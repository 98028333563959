<template>
  <section id="map">
    <header></header>
    <article>
      <header>
        <button>
          <span class="label">Download Verlauf</span>
          <span class="selection">Transitgas-Leitung</span>
        </button>
        <button style="display: none">
          <span class="label">Download Standorte</span>
          <span class="selection">Mitarbeitende</span>
        </button>
      </header>
    </article>
  </section>
</template>

<script>
export default {
  name: "Map",
  props: {
    appData: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#map {
  padding: 1em;
}
#map > article {
  margin-bottom: 1em;
}
#map > article > header {
  display: flex;
  margin-bottom: 1em;
}
</style>